import Vue from 'vue'
import VueRouter from 'vue-router'
import Mian from '../layout/main.vue'

import store from '../store/index.js'
import {
	Notify
} from 'vant';

import NProgress from "nprogress"; // 进度条；进度指示器
import "nprogress/nprogress.css"; // 进度条；进度指示器 样式

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Main',
		component: Mian,
		redirect: '/index',
		children: [{
				path: '/index',
				name: 'Index',
				meta: {
					isKey: false,
					needLogin: false,
				},
				component: () => import('../views/Index.vue')
			},
			{
				path: '/search',
				name: 'search',
				meta: {
					isKey: true,
					needLogin: false,
				},
				component: () => import('../views/search.vue')
			},
			{
				path: '/intro',
				name: 'intro',
				component: () => import('../views/intro.vue')
			},
			{
				path: '/news/:type',
				name: 'News',
				meta: {
					isKey: true,
					needLogin: false,
				},
				component: () => import('../views/news.vue')
			},
			{
				path: '/detail',
				name: 'Detail',
				meta: {
					isKey: true,
					needLogin: false,
				},
				component: () => import('../views/detail.vue')
			},
			{
				path: '/concat',
				name: 'Concat',
				meta: {
					isKey: false,
					needLogin: false,
				},
				component: () => import('../views/concat.vue')
			},
			{
				path: '/expert',
				name: 'expert',
				meta: {
					isKey: false,
					needLogin: false,
				},
				component: () => import('../views/expert.vue')
			},
			{
				path: '/expertDetail',
				name: 'expertDetail',
				meta: {
					isKey: false,
					needLogin: false,
				},
				component: () => import('../views/expertDetail.vue')
			},
			{
				path: '/video',
				name: 'video',
				meta: {
					isKey: false,
					needLogin: true,
				},
				component: () => import('../views/video.vue')
			},
			{
				path: '/study/:id',
				name: 'study',
				meta: {
					isKey: true,
					needLogin: false,
					isKeep: false,
				},
				component: () => import('../views/study.vue')
			},
			{
				path: '/center',
				name: 'center',
				meta: {
					isKey: false,
					needLogin: true,
				},
				component: () => import('../views/center.vue'),
				redirect: '/center/index',
				children: [{
						path: '/center/index',
						name: 'CenterIndex',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/index.vue')
					},
					{
						path: '/center/info',
						name: 'CenterInfo',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/info.vue')
					},
					{
						path: '/center/record',
						name: 'CenterRecord',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/record.vue')
					},
					{
						path: '/center/record2',
						name: 'CenterRecord2',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/record2.vue')
					},
					{
						path: '/center/files',
						name: 'CenterFiles',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/files.vue')
					},
					{
						path: '/center/testCate',
						name: 'center/testCate',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/testCate.vue') 
					},
					{
						path: '/center/test3',
						name: 'center/test3',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/test2.vue')
					},
					{
						path: '/center/test',
						name: 'center/test',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/kaoshi.vue')
					},
					{
						path: '/center/test2',
						name: 'center/test2',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/kaoshi2.vue')
					},
					{
						path: '/center/kaoshi_log',
						name: 'center/kaoshi_log',
						component: () => import('../views/center/kaoshi_log.vue')
					},
					{
						path: '/center/fail',
						name: 'center/fail',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/fail.vue')
					},
					{
						path: '/center/collect',
						name: 'center/collect',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/collect.vue')
					},
					{
						path: '/center/study/:typeId/:title',
						name: 'center/study',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/study.vue')
					},
					{
						path: '/center/case',
						name: 'center/case',
						meta: {
							isKey: false,
							needLogin: true,
						},
						component: () => import('../views/center/case.vue')
					}
				]
			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/register.vue')
	},
	{
		path: '/forget',
		name: 'Forget',
		component: () => import('../views/forget.vue')
	}
]

const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) {
		return savedPosition
	} else {
		return {
			x: 0,
			y: 0
		}
	}
}

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior
})

router.beforeEach((to, from, next) => {
	let token = window.localStorage.getItem('token')
	if (token) {
		store.dispatch('vuex_get_user_info')
		store.commit('setLoginStatus', true)
	}

	if (to.meta.needLogin && !store.state.isLogin) {
		Notify({
			type: 'danger',
			message: '请登录后再操作'
		})
		next(to.meta.needLogin ? '/login' : to.fullPath);
	} else {
		NProgress.start();
		next();
	}

});
router.afterEach(() => {
	NProgress.done();
});

export default router
