import Vue from 'vue'
import Vuex from 'vuex'

import { Toast } from 'vant';

import router from '../router'
import {getUserInfo} from "@/api/common.js"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLogin: false, //是否登录
		user_info: {},
		majorList:[
			{name:'初级',value:'1'},
			{name:'中级',value:'2'},
			{name:'高级',value:'3'}
		],//专业列表
		webInfo:{},
		menuStatus:false,
	},
	mutations: {
		setMenuStatus(state,status=false){
			state.menuStatus = status
		},
		//设置登陆状态
		setLoginStatus(state, status = true) {
			state.isLogin = status
		},
		//设置用户信息
		setUserInfo(state, info = {}) {
			state.user_info = info
		},
		//设置网站基本信息
		setWebInfo(state,info={}){
			state.webInfo = info
		}
	},
	actions: {
		async vuex_get_user_info(context){
			let res = await getUserInfo()
			if(res.code == 50004){
				Toast.fail('用户登录过期请重新登录')
				// Toast({
				// 	type: 'danger',
				// 	message: '用户登录过期请重新登录',
				// 	duration: 1500
				// })
				window.localStorage.removeItem('token')
				context.commit('setLoginStatus', false)
				router.replace({
					path:'/login'
				})
				return false;
			}
			if(res.code == 200){
				context.commit('setLoginStatus', true)
				context.commit('setUserInfo', res.data)
				return false
			}
			Toast.fail(res.message)
			
		},
		//退出
		vuex_out(context){
			//退出登陆
			Toast.success('退出成功')
			window.localStorage.removeItem('token')
			context.commit('setLoginStatus', false)
			context.commit('setUserInfo')
			if(router.currentRoute.fullPath != '/index'){
				router.replace({
					path:'/'
				})
			}
		},
	},
	modules: {}
})
