import router from "./index.js"

export const to_link = (route,params={},type=1)=>{
	if(type == 1){
		router.push({
			path:route,
			query:params
		})
	}
}

export const to_redirect = (route,params={},type=1)=>{
	if(type == 1){
		router.push({
			path:route,
			query:params,
			replace: true
		})
	}else{
		router.push({
			name:route,
			params:params,
			replace: true
		})
	}
}

//富文本处理
export const format_html = (html)=>{
	let newContent= html.replace(/<img[^>]*>/gi,function(match,capture){
	    match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
	    match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
	    match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
	    return match;
	  });
	  newContent = newContent.replace(/style="[^"]+"/gi,function(match,capture){
	    match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
	    return match;
	  });
	  newContent = newContent.replace(/<br[^>]*\/>/gi, '');
	  newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"');
	  return newContent;
}