<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				title: '',
				keywords: '',
				description: '',

			}
		},
		created() {
			isMobile()
		},
		methods: {

		}
	}

	function isMobile() {
		if (window.navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)) {
			// window.location.href = 'http://mobile.yubo327.com/'
			return true; // 移动端
		} else {
			window.location.href = 'http://yubo327.com'
			return false; // PC端
		}
	}
</script>

<style lang="scss">
	@import './assets/style/uview/common.scss';
	@import './assets/style/uview/style.vue.scss';

	* {
		margin: 0;
		padding: 0;
	}

	#app {
		width: 750px;
	}
  .m-layout-bottom-beian{
    background: #06355b !important;
  }

	.van-toast {
		flex-direction: row !important;
		min-height: 0 !important;
		padding: 12Px 16Px !important;
	}

	.van-toast__text {
		margin-top: 0 !important;
	}

	.van-toast__icon {
		font-size: 18Px !important;
	}

	.m-toast-fail {
		background-color: #fff!important;
		color: #fa3534!important;
		// border: 1px solid #fa3534;
	}
	
	.m-toast-success{
		background-color: #fff!important;
		color: #42acdc!important;
		// border: 1px solid #42acdc;
	}
	
</style>
