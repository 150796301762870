<template>
	<div class="m-layout" style="padding-top:40px;
}">
		<div class="m-nav" style="position: fixed;
    z-index: 999;
    top: 0;
    background: #fff;width:100%;">
			<div class="m-nav-left" @click="selectNav('/index')">
				<img :src="webInfo.web_logo" />
<!--				<span class="u-m-l-8 u-line-1" style="width: 2.8rem;">{{webInfo.title}}</span>-->
			</div>
			<div class="m-nav-right">
				<div class="u-flex" v-if="!isLogin" @click="to_link('/login')">
					<img src="../assets/img/common/login-icon.png" />
					<span class="m-nav-font1">学习登录</span>
				</div>
				<div class="u-flex" v-else @click="to_link('/center/index')">
					<img src="../assets/img/common/login-icon.png" />
					<span class="m-nav-font1">个人中心</span>
				</div>
				<div class="u-flex u-m-l-30" v-if="isLogin" @click="goOut()">
					<img src="../assets/img/common/icon-out.png" />
					<span class="m-nav-font1">退出</span>
				</div>
				<div class="m-nav-btn" @click="$refs.mMenu.setNavShow()">
					<img src="../assets/img/common/icon-nav-menu.png" />
				</div>
			</div>
		</div>
		<!-- <div class="m-search">
			<img src="../assets/img/common/icon-search.png" class="m-search-icon" />
			<van-field label="">
				<template #input>
					<input v-model="keyword" id="keyword" placeholder="请输入关键字" />
				</template>
			</van-field>
		</div> -->

		<div class="m-layout-center">
			<router-view v-if="$route.meta.isKey" :key="key" />
			<router-view v-else />
		</div>

		<div class="m-layout-bottom">
			<!-- <div class="m-layout-bottom-main">
				<div class="m-layout-bottom-main-left"><img src="../assets/logo.png"></div>
				<div class="m-layout-bottom-main-center">
					<ul>
						<li @click="selectNav('/index')"><span>首页</span></li>
						<li @click="selectNav('/intro')"><span>公司简介</span></li>
						<li @click="selectNav('News',{type:3},2)"><span>培训信息</span></li>
						<li @click="selectNav('News',{type:1},2)"><span>新闻资讯</span></li>
					</ul>
				</div>
				<div class="m-layout-bottom-main-center">
					<ul>
						<li @click="selectNav('News',{type:2},2)"><span>最新资讯</span></li>
						<li @click="selectNav('/expert')"><span>专家团队</span></li>
						<li @click="selectNav('/concat')"><span>联系我们</span></li>
					</ul>
				</div>
				<div class="m-layout-bottom-main-right">
					<div class="u-flex u-col-top">
						<div class="m-label">电话：</div>
						<div class="u-flex-col">
							<span>{{webInfo.contact_way}}</span>
							<span class="m-t-8">{{webInfo.phone}}</span>
						</div>
					</div>
					<div class="u-flex u-col-top m-t-8">
						<div class="m-label">邮箱：</div>
						<div class="u-flex-col">
							<span>{{webInfo.email}}</span>
						</div>
					</div>
					<div class="u-flex u-col-top m-t-8">
						<div class="m-label">地址：</div>
						<div class="u-flex-col">
							<span>{{webInfo.address || ''}}</span>
						</div>
					</div>
				</div>
			</div> -->
			<div class="m-layout-bottom-beian">
				<span>版权所有 : {{webInfo.copyright}} </span>
				<span @click="toUrl">网站备案号 : {{webInfo.AQ}}</span>
			</div>
		</div>
		<!-- 菜单栏 -->
		<m-menu  ref="mMenu"></m-menu>
		<!-- <van-popup position="left" v-model="navShow">
			<div class="m-popup">
				<div class="m-nav">
					<div class="m-nav-left">
						<img src="../assets/logo.png" />
						<span class="u-m-l-8">北京雨博文化</span>
					</div>
					<div class="m-nav-right">
						<div class="u-flex">
							<img src="../assets/img/common/login-icon3.png" />
							<span class="m-nav-font1">学习登录</span>
						</div>
						<div class="m-nav-btn" @click="navShow = false">
							<van-icon size="20" color="#fff" name="cross" />
						</div>
					</div>
				</div>
				<ul class="u-border-top">
					<li class="u-border-bottom">
						<span>首页</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>公司简介</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>培训信息</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>新闻资讯</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>培训导航</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>专家团队</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom">
						<span>联系我们</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
				</ul>
			</div>
		</van-popup> -->
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		getSiteInformation
	} from "@/api/common.js"
	import mMenu from "../components/u-menu.vue"
	import {
		to_link,
		to_redirect
	} from "../router/api.js"
	import {
		Dialog
	} from 'vant';
	export default {
		components: {
			mMenu
		},
		data() {
			return {
				keyword: '',
				bottomClass:'m-layout-bottom'
			}
		},
		computed: {
			key() {
				return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
			},
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		created() {
			this.getWebInfo()


		},
		mounted() {
			// document.querySelector('#keyword').onkeypress = (event) => {
			// 	if (event.which === 13) {
			// 		console.log(this.$router);
			// 		to_link('/search', {
			// 			keyword: this.keyword,
			// 			key: Date.now()
			// 		})
			// 	}
			// }

		},
		methods: {
			to_link,
			...mapActions([
				'vuex_out'
			]),
			toUrl(){
				if(!this.webInfo.url) return false;
				window.location.href = this.webInfo.url
			},
			getWebInfo() {
				getSiteInformation().then(res => {
					if (res.code == 200) {
						document.title = res.data.title || ''
						document.querySelector('meta[name="description"]').content = res.data.describes || ''
						document.querySelector('meta[name="keywords"]').content = res.data.keyword || ''
						this.$store.commit('setWebInfo', res.data)
					} else {
						this.$message.error(res.message)
					}
				})
			},
			selectNav(path, params = {}, type = 1) {


				let pagePath = this.$route.fullPath
				let routeParams = this.$route.query
				let page2 = ''
				if (type == 2) {
					for (let i in params) {
						page2 = page2 + '/' + params[i]
					}
				}

				if ((type == 1 && pagePath == path) || (type == 2 && pagePath == `/${path.toLowerCase()}${page2}`)) {
					this.navShow = false
					return false
				}
				this.navShow = false
				to_redirect(path, params, type)
			},
			goOut() {
				Dialog.confirm({
					title: '标题',
					message: '确认退出吗?'
				}).then(() => {
					this.vuex_out()
				}).catch(() => {});
			}
		}
	}
</script>

<style lang="scss" scoped>
	 .m-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0.3rem 0.133rem;
		color: #fff;
		position: fixed;
		width: 100%;
		top: 80px;
	}
	.m-layout-bottom{
		position: fixed;
		bottom: 0px;
		width: 100%;
		margin:0 !important;
	}
	.m-nav {
		display: flex;
		justify-content: space-between;
		padding: 24px 20px;
	}

	.m-nav-font1 {
		font-size: 24px;
		color: #606060;
		margin-left: 14px;
	}

	.m-nav-left {
		display: flex;
		align-items: flex-end;
		font-size: 28px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 37px;
		color: #3A3A3A;
	}

	.m-nav-left img {
		width: 260px;
		height: 68px;
	}

	.m-nav-right {
		display: flex;
		align-items: center;
	}

	.m-nav-right img {
		width: 44px;
		height: 44px;
	}

	.m-nav-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 45px;
		height: 36px;
		margin-left: 70px;
	}

	.m-nav-btn img {
		width: 45px;
		height: 23px;
	}

	.m-search {
		display: flex;
		align-items: center;
		width: 710px;
		height: 60px;
		background: #F5F5F5;
		border-radius: 34px;
		margin: 10px 20px 23px 20px;
		padding: 0 30px;

		input {
			outline: none;
			border: 0;
			background: none;
			flex: 1;
		}
	}

	.m-search-icon {
		width: 30px;
		height: 30px;
	}
	.m-layout{
		margin-bottom: 100px;
	}

	.m-layout-center {
		min-height: calc(100vh - 180px);
	}

	.m-layout-bottom{

	}
	.m-layout-bottom2{
		position: fixed;
		bottom: 0;
		width: 100%;
	}
	.m-layout-bottom-main {
		display: flex;
		width: 750px;
		height: 260px;
		background: #403F45;
		padding-top: 50px;
		padding-left: 34px;
	}

	.m-layout-bottom-main-left img {
		width: 75px;
		height: 48px;
	}

	.m-layout-bottom-main-center {
		width: 130px;
		margin-left: 25px;
	}

	.m-layout-bottom-main-center ul {
		min-height: 160px;
		border-right: 1px solid #707070;
	}

	.m-layout-bottom-main-center ul li {
		display: flex;
		box-sizing: content-box;
		font-size: 24px;
		color: #ACACAC;
		line-height: 30px;
		padding-bottom: 10px;
	}

	.m-layout-bottom-main-right {
		flex: 1;
		padding-left: 24px;
		font-size: 24px;
		color: #ACACAC;
	}

	.m-t-8 {
		margin-top: 8px;
	}

	.m-layout-bottom-beian {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		// background: #403F45;
		background: #188EEE;
		font-size: 22px;
		color: #fff;
		// border-top: 1px solid #707070;
		padding: 15px 0;
		box-sizing: content-box;
	}




	.m-popup {
		width: 100vw;
		height: 100vh;
		background: #00B496;
	}

	.m-popup .m-nav-left,
	.m-popup .m-nav-font1 {
		color: #fff;
	}

	.m-popup ul {
		margin-top: 100px;
	}

	.m-popup ul li {
		display: flex;
		align-items: center;
		padding-left: 102px;
		height: 80px;
		font-size: 30px;
		color: #fff;
		padding-right: 24px;
		justify-content: space-between;
	}
</style>

<style>
	.m-search .van-cell {
		padding: 0 16px !important;
		background-color: #f5f5f5 !important;
	}
</style>
