<template>
	<div>
		<!-- 菜单栏 -->
		<van-popup position="left" v-model="navShow">
			<div class="m-popup">
				<div class="m-nav">
					<div class="m-nav-left">
						<img src="../assets/logo.png" />
						<span class="u-m-l-8">在线学习系统</span>
					</div>
					<div class="m-nav-right">
						<!-- <div class="u-flex" v-if="isShowLogin">
							<div class="u-flex" v-if="!isLogin">
								<img src="../assets/img/common/login-icon3.png" />
								<span class="m-nav-font1">学习登录</span>
							</div>
							<div class="u-flex" v-else>
								<img src="../assets/img/common/login-icon3.png" />
								<span class="m-nav-font1">学习登录</span>
							</div>
						</div> -->
						<div class="m-nav-btn" @click="navShow = false">
							<van-icon size="20" color="#fff" name="cross" />
						</div>
					</div>
				</div>
				<ul class="u-border-top">
					<li class="u-border-bottom" @click="selectNav('/index')">
						<span>首页</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('/intro')">
						<span>公司简介</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('News',{type:3},2)">
						<span>培训信息</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('News',{type:1},2)">
						<span>新闻资讯</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('News',{type:2},2)">
						<span>最新资讯</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" v-for="(item,index) in typeList" @click="selectNav('study',{id:item.id},2)">
						<span>{{item.name}}</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('/expert')">
						<span>专家团队</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="selectNav('/concat')">
						<span>联系我们</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
					<li class="u-border-bottom" @click="showAppQR">
						<span>APP下载</span>
						<van-icon size="16" color="#fff" name="arrow" />
					</li>
				</ul>
			</div>
		</van-popup>
		<div :class="act"  @click="showAppQR"  style="background-color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;"></div>
		 <img :class="act"   @click="showAppQR"  style="max-width: 80%; 
	             max-height: 80%; 
	             z-index: 99;  
				 position: fixed;
				     top: 20%;
				     margin: 0 auto;
				     left: 0;
				     right: 0; 
					 " :src="webInfo.app_qrcode" alt="Your Image" />  
	</div>
  
	  
</template>  

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		to_redirect
	} from "../router/api.js"
	import {getTopCate} from "../api/index.js"
	export default {
		name: 'm-menu',
		props: {
			isShowLogin: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				navShow: false,
				typeList:[],
				act:'nact',
				 
			}
		},
		computed: {
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		watch:{
			navShow(newV){
				if(!newV){
					setTimeout(()=>{
						this.$store.commit('setMenuStatus',newV)
					},300)
				}else{
					this.$store.commit('setMenuStatus',newV)
				}
			}
		},
		created() {
			this.getType()
			 
		},
	
		methods: {
			showAppQR(){
				if(this.act=="act"){
					this.act="nact"
				}else{
						this.act="act"
						this.navShow=false;
				}
			},
			toHref(){
				window.location.href="http://www.yubo327.com/download.html"
			},
			async getType() {
				if (this.typeList.length > 0) return false;
				let res = await getTopCate()
				if (res.code == 200) {
					this.typeList = res.data
				} else {
					this.$message.error(res.message)
				}
			},
			setNavShow(show = true) {
				this.navShow = show
			},
			selectNav(path, params = {}, type = 1) {
				let pagePath = this.$route.fullPath
				let routeParams = this.$route.query
				let page2 = ''
				if (type == 2) {
					for (let i in params) {
						page2 = page2 + '/' + params[i]
					}
				}

				if ((type == 1 && pagePath == path) || (type == 2 && pagePath == `/${path.toLowerCase()}${page2}`)) {
					this.navShow = false
					return false
				}
				this.navShow = false
				to_redirect(path, params, type)
			},
		}

	}
</script>

<style lang="scss" scoped>
	 .centered-image {
	             
	         }
	 .act{
		 display: block;
	 }	
		  .nact{
			  display: none;
		  }
	
	.m-nav {
		display: flex;
		justify-content: space-between;
		padding: 24px 20px;
	}

	.m-nav-font1 {
		font-size: 24px;
		color: #606060;
		margin-left: 20px;
	}

	.m-nav-left {
		display: flex;
		align-items: flex-end;
		font-size: 28px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 37px;
		color: #3A3A3A;
	}

	.m-nav-left img {
		width: 75px;
		height: 48px;
	}

	.m-nav-right {
		display: flex;
		align-items: center;
	}

	.m-nav-right img {
		width: 36px;
		height: 36px;
	}

	.m-nav-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 45px;
		height: 36px;
		margin-left: 70px;
	}

	.m-nav-btn img {
		width: 45px;
		height: 23px;
	}

	.m-popup {
		width: 100vw;
		height: 100vh;
		background: #188EEE;
		z-index: 9999;
	}

	.m-popup .m-nav-left,
	.m-popup .m-nav-font1 {
		color: #fff;
	}

	.m-popup ul {
		margin-top: 100px;
	}

	.m-popup ul li {
		display: flex;
		align-items: center;
		padding-left: 102px;
		height: 80px;
		font-size: 30px;
		color: #fff;
		padding-right: 24px;
		justify-content: space-between;
	}
</style>
