import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible' 

import { Toast } from 'vant';
import layer from "layui-layer"

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// import MetaInfo from 'vue-meta-info';
// Vue.use(MetaInfo);
console.log(layer);
Vue.prototype.$message = {
	error:function(msg=''){
		layer.msg(msg,{
			icon:2,
			time:1500
		})
		// Toast({
		// 	type:'fail',
		// 	message:msg,
		// 	className:'m-toast-fail cross',
		// 	duration:1000
		// });
	},
	success:function(msg=''){
		layer.msg(msg,{
			icon:1,
			time:1500
		})
		// Toast({
		// 	type:'success',
		// 	message:msg,
		// 	className:'m-toast-success',
		// 	duration:1000
		// });
	}
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
      // document.dispatchEvent(new Event('render-event'))

    },
}).$mount('#app')
